import React from 'react'
import Slider from "react-slick";
import { banner1, banner2, p1, p2,p3 } from '../../constant';

function HeaderBanner() {
    const settings = {
        arrows: false,
        dots: true,
        infinite: true,
        fade: true,
        // speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: false,
        prevArrow: false,
      };
      const BannerData = [
        {
          id: 1,
          img: banner1,
          title: "Meet the world's ",
          sub: "coolest E-Bikes",
          desc: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        },
        {
          id: 2,
          img: banner2,
          title: "Meet the world's ",
          sub: "coolest E-Bikes",
          desc: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        },
        {
          id: 3,
          img: banner2,
          title: "Meet the world's ",
          sub: "coolest E-Bikes",
          desc: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        },
      ];
  return (
        <Slider {...settings}>
          {BannerData?.map((item, index) => {
            return (
                <>
                 <img src={item?.img} alt="" className='image-fluid image-width' />
                <div className='banner-content'>
                     <div className='banner-inner'>
                         <h2 className='banner-rotateText'>Swift</h2>
                         <h2 className='banner-title'>E Bikes</h2>
                     </div>
                     <hr className='hr-line'/>
                     <div>
                         <h4 className='banner-subTitle'> Premium Bikes</h4>
                         <h4 className='banner-subTitle'>at Affordable Prices</h4>
                     </div>
                </div>
                </>
            
            );
          })}
        </Slider>   
  )
}

export default HeaderBanner