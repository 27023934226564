import react, { useState } from 'react';
import '../../assets/css/Checkout.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { visa_img, prod_img } from '../../constant';
import StripeForm from '../../components/Stripe/Stripe';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const Checkout = () => {
    const [location, setLocation] = useState("location_1");
    const [pay, setPay] = useState("payment_1");
    const [openConfirm, setOpenConfrim] = useState(false);

    const LocationVal = (e) => {
        setLocation(e)
    }
    const Payment = (e) => {
        setPay(e);
    }

    console.log('open confrim', openConfirm);


    let address = [
        {
            location: "location_1",
            country: "United States",
            state: "Texas",
            address: "10515 Fox Ave Fairdale, Kentucky(KY), 40118",
            phone: "+44-123-456-789"
        },
        {
            location: "location_2",
            country: "United States",
            state: "Texas",
            address: "10515 Fox Ave Fairdale, Kentucky(KY), 40118",
            phone: "+44-123-456-789"
        }
    ];

    let payment = [
        {
            payment: "payment_1",
            img: visa_img,
            card_name: "My Personal Card",
            card_number: "*********1239",
        },
        {
            payment: "payment_2",
            img: visa_img,
            card_name: "My Personal Card",
            card_number: "*********1239",
        },
    ]

    return (
        <>
             <Header bg={true}/>
             <div className='checkout_page'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 text-center'>
                        <p className='main_heading'>Checkout</p>
                        <p className='checkout_p mb-5'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
                        </p>
                    </div>
                    <div className='col-lg-7 pr-5'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <p className='checkout_subheading'>Shipment Details</p>
                            </div>
                            <div className='col-lg-6 text-right'>
                                <p className='checkout_p cursor_pointer'>Already Have an account? Login</p>
                            </div>
                            <div className='col-lg-6'>
                                <p className='use_save_head'>Use Saved Addresses</p>
                            </div>
                            <div className='col-lg-12'>
                                {address.map((item, index) => {
                                    return (
                                        <div key={index} className={(location === `${item.location}`) ? `crd-btn-active my-3 ` : `crd-btn-nonactive my-3`}>
                                            <div className='flx-radio-address '>
                                                <div className='radio_portion '>
                                                    <input type='radio'
                                                        onChange={() => { LocationVal(`${item.location}`) }}
                                                        value={`${item.location}`}
                                                        name='saved_address' />
                                                </div>
                                                <div className='text-portion'>
                                                    <div className='top_portion'>
                                                        <div className='d-flex'>
                                                            <p>Country: <span>United States</span></p>
                                                            <p className='ml-5'>State: <span>Texas</span></p>
                                                        </div>
                                                        {(location === `${item.location}`) ? <div>
                                                            <FontAwesomeIcon
                                                                icon={faCircleCheck}
                                                                className="tick-color"
                                                            />
                                                        </div> : <></>}
                                                    </div>
                                                    <p>Address : 10515 Fox Ave Fairdale, Kentucky(KY), 40118</p>
                                                    <p className='mb-0'>Phone : +44-123-456-789</p>
                                                    {(index === 0) ? <span className='default_opt'>Default</span> : <></>}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                            <div className='col-lg-12 mt-4 mb-5'>
                                <input type='radio' />   <span className='checkout_subheading mt-5 ml-2'>Add New Address</span>
                            </div>
                            <div className='col-lg-12'>
                                <p className=''>Use Saved Addresses</p>
                            </div>
                            <div className='col-lg-12 mb-4'>
                                <select className='form-control form-select select_field'>
                                    <option>Select Shipment Method</option>
                                    <option>Select Shipment Method</option>
                                    <option>Select Shipment Method</option>
                                </select>
                            </div>
                            <div className='col-lg-6 mb-2'>
                                <p className='mt-3'>First Name</p>
                                <input type='text' placeholder='John' className="form-control inpt_fld" />
                            </div>
                            <div className='col-lg-6 mb-2'>
                                <p className='mt-3 checkout_label'>Last Name</p>
                                <input type='text' placeholder='Doe' className="form-control inpt_fld" />
                            </div>
                            <div className='col-lg-6 mb-4'>
                                <p className='mt-3 checkout_label'>Phone</p>
                                <input type='text' placeholder='+1 123 456 7890' className="form-control inpt_fld" />
                            </div>
                            <div className='col-lg-6 mb-4'>
                                <p className='mt-3 checkout_label'>Email</p>
                                <input type='text' placeholder='Johndoe@email.com' className="form-control inpt_fld" />
                            </div>
                            <div className='col-lg-12 mb-4'>
                                <p className='mt-3 checkout_label'>Address</p>
                                <input type='text' placeholder='Your Address Here' className="form-control inpt_fld" />
                            </div>
                            <div className='col-lg-12 mb-4'>
                                <p className='mt-3 checkout_label'>Suite,Apartment,etc</p>
                                <input type='text' placeholder='# 000' className="form-control inpt_fld" />
                            </div>
                            <div className='col-lg-12 mb-4'>
                                <p className='mt-3 checkout_label'>City</p>
                                <input type='text' placeholder='Your City Here' className="form-control inpt_fld" />
                            </div>
                            <div className='col-lg-4 mb-4'>
                                <p className='mt-3 checkout_label'>Country/Region</p>
                                <input type='text' placeholder='Country Here' className="form-control inpt_fld" />
                            </div>
                            <div className='col-lg-4 mb-4'>
                                <p className='mt-3 checkout_label'>State</p>
                                <input type='text' placeholder='State Here' className="form-control inpt_fld" />
                            </div>
                            <div className='col-lg-4 mb-4'>
                                <p className='mt-3 checkout_label'>Zip Code</p>
                                <input type='text' placeholder='Zip code Here' className="form-control inpt_fld" />
                            </div>
                            <div className='col-lg-12 mb-4 mt-2'>
                                <span class="checkboxOverride">
                                    <input type="checkbox"
                                        name="" id="checkboxInputOverride" value="1" />
                                    <label for="checkboxInputOverride"></label>
                                </span>
                                <span className='clr-light-save ml-3'>Save Shipping Details</span>
                            </div>
                            <div className='col-lg-12'>
                                <p className='checkout_subheading mt-4 mb-5'>Payment Details</p>
                            </div>
                            <div className='col-lg-12'>
                                <p className='use_save_head'>Use Saved Addresses</p>
                            </div>
                            <div className='col-lg-12'>

                                {payment.map((item, index) => {
                                    return (
                                        <div key={index} className={(item.payment === pay) ? 'crd-btn-active-payment my-4' : 'crd-btn-non-active-payment my-4'}>
                                            <div className='flx-radio-address_visa'>
                                                <div className='d-flex'>
                                                    <div className='radio_portion-payment  border-right-0 '>
                                                        <input
                                                            onClick={() => { Payment(item.payment) }}
                                                            type='radio' name='saved_payment' />
                                                    </div>
                                                    <img src={visa_img} alt='payemnt ico' />
                                                    <div className='ml-4 my-personal'>
                                                        <p className='lh-01 mb-0'>My Personal Card</p>
                                                        <p className='mb-0'>*********1239</p>
                                                    </div>
                                                </div>
                                                <div className='d-flex_visa_opt'>
                                                    {(index === 0) ?
                                                        <span className='default_opt_nopos'>Default </span>
                                                        : <></>
                                                    }
                                                    {(item.payment === pay)
                                                        ? <span className='ml-4'>
                                                            <FontAwesomeIcon
                                                                icon={faCircleCheck}
                                                                className="tick-color"
                                                            />
                                                        </span> : <></>
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                    )
                                })}

                                {/* <div className='crd-btn-active-payment my-4'>
                                    <div className='flx-radio-address_visa '>
                                        <div className='d-flex'>
                                            <div className='radio_portion-payment  border-right-0 '>
                                                <input
                                                    //  onClick={()=>{Payment(item.payment)}}
                                                    type='radio' name='saved_payment' />
                                            </div>
                                            <img src={visa_img} alt='payemnt ico' />
                                            <div className='ml-4 my-personal'>
                                                <p className='lh-01 mb-0'>My Personal Card</p>
                                                <p className='mb-0'>*********1239</p>
                                            </div>
                                        </div>
                                        <div className='d-flex_visa_opt'>
                                            <span className='default_opt_nopos'>Default </span>
                                            <span className='ml-4'>
                                                <FontAwesomeIcon
                                                    icon={faTwitter}
                                                    className="tick-color"
                                                />
                                            </span>
                                        </div>

                                    </div>
                                </div> */}
                            </div>
                            <div class="col-lg-12 mt-4 mb-5">
                                <input type="radio" />
                                <span class="checkout_subheading mt-5 ml-3">Add New Payments
                                </span>
                            </div>

                            <div className='col-lg-12'>
                                <StripeForm />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-5 pl-4'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <p class="checkout_subheading">Order Summary</p>
                            </div>
                            <div class="col-lg-8 mb-4">
                                <p class="mt-3 checkout_label">
                                    Suite,Apartment,etc
                                </p>
                                <input type="text" placeholder="Enter Promo Code" class="form-control inpt_fld" />
                            </div>
                            <div class="col-lg-4">
                                <button className='btn btn-light btn-apply'>
                                    Apply
                                </button>
                            </div>
                            <div class="col-lg-12">
                                <div className='crd-details'>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <p className='product_details'>Product Details</p>
                                        </div>

                                        <div className='col-lg-6'>
                                            <div className='d-flex'>
                                                <div>
                                                    <img src={prod_img} />
                                                </div>
                                                <div className='ml-2'>
                                                    <span className='prod_id'>E-MB 006 <br /></span>
                                                    <span className='prod_name'>Lorem Ipsum</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 text-right'>
                                            <p className='price_prod'>$158.07 </p>
                                        </div>
                                        <div className='col-lg-12'>
                                            <div className='brdr-btm-light my-3'>

                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='d-flex'>
                                                <div>
                                                    <img src={prod_img} />
                                                </div>
                                                <div className='ml-2'>
                                                    <span className='prod_id'>E-MB 006 <br /></span>
                                                    <span className='prod_name'>Lorem Ipsum</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 text-right'>
                                            <p className='price_prod'>$158.07 </p>
                                        </div>
                                        <div className='col-lg-12'>
                                            <div className='brdr-btm-light my-3'>

                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='d-flex'>
                                                <div>
                                                    <img src={prod_img} />
                                                </div>
                                                <div className='ml-2'>
                                                    <span className='prod_id'>E-MB 006 <br /></span>
                                                    <span className='prod_name'>Lorem Ipsum</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 text-right'>
                                            <p className='price_prod'>$158.07 </p>
                                        </div>
                                        <div className='col-lg-12'>
                                            <div className='brdr-btm-light my-3'>

                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <p className='card_price'>Item Total :</p>
                                        </div>
                                        <div className='col-lg-6 text-right'>
                                            <p className='card_bill'>$50</p>
                                        </div>
                                        <div className='col-lg-6'>
                                            <p className='card_price'>Shipment & Delivery :</p>
                                        </div>
                                        <div className='col-lg-6 text-right'>
                                            <p className='card_bill'>$0</p>
                                        </div>
                                        <div className='col-lg-6'>
                                            <p className='card_price'>Promo Applied :</p>
                                        </div>
                                        <div className='col-lg-6 text-right'>
                                            <p className='card_bill'>$-5</p>
                                        </div>
                                        <div className='col-lg-12'>
                                            <div className='brdr-btm-light my-3'>

                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <p className='card_total'>Order Total</p>
                                        </div>
                                        <div className='col-lg-6 text-right'>
                                            <p className='card_total_bill'>$45</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mb-5'>
                    <div className='col-lg-12 mt-3 mb-5 p-0'>
                        <div className='brdr-btm-light'>

                        </div>
                    </div>
                    <div className='col-lg-6 align-self-center p-0'>
                        <input type='checkbox' className='mr-3' />
                        <span className='confirm_order_text'>Proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span>
                    </div>
                    <div className='col-lg-6 text-right'>
                        <button onClick={() => { setOpenConfrim(true) }} className='btn btn-light cnfrm_btn'>
                            Confirm Order
                        </button>
                    </div>
                    {/* {(openConfirm) ? <div className='modal_confirm_order'>
                        <h1>
                            Helllooooooooooooo
                        </h1>
                        <button onClick={() => { setOpenConfrim(false) }}>
                            <h1>Close It</h1>
                        </button>
                    </div> :
                        <><h1>IUJSAHDIKJHKSJAHDK</h1></>
                    } */}
                </div>
            </div>


        </div>
             <Footer />
        </>
        
    )
}

export default Checkout;