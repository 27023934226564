import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { cartp } from "../../constant";

function ViewOrderModal(props) {
  const { setIsOpenModal, isOpenModal, selectedData, setSelectedData } = props;

  const data = [
      {
          id:1,
          name: 'Product1',
          qty: '2',
          price: '100',
          order_id: '1',
          delivery_status: 'pending',
      },
      {
        id:2,
        name: 'Product2',
        qty: '2',
        price: '100',
        order_id: '1',
        delivery_status: 'pending',
    },
    {
        id:3,
        name: 'Product3',
        qty: '2',
        price: '100',
        order_id: '1',
        delivery_status: 'pending',
    },
  ]

  console.log(selectedData)
  return (
    <>
      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(false);
        }}
        className=" orderView-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="modal-close"
          onClick={() => setIsOpenModal(false)}
        />
        <div className="inner-content">
          <h2 style={{ textAlign: "center" }}>Order Details</h2>
          <table>
            <thead>
              <tr className="topbar">
                <th></th>
                <th className="productDetail">PRODUCT NAME</th>
                <th className="Proprice">Amount</th>
                <th className="Proprice">Quantity</th>
                <th className="productDetail">OrderNo</th>
                <th className="productDetail">Status</th>
              </tr>
            </thead>

            <tbody>
            {data?.map((item, index) => {
              console.log(item);
                return (
                  <tr className="products modal-padding" key={index}>
                    <td className="Proprice">
                      <figure className="modal-productImg">
                        <img
                          src={cartp}
                          alt=""
                          className="image-fluid image-width"
                        />
                      </figure>
                    </td>
                    <td className="productDetail">
                      <div className="product-detail">
                        <div className="name">
                          <p className="font-13">{item?.name}</p>
                        </div>
                      </div>
                    </td>
                    <td className="name">
                      <p className="font-13"> {item?.price}</p>
                    </td>
                    <td className="name">
                      <p className="font-13"> {item?.qty}</p>
                    </td>
                    <td className="name">
                      <p className="font-13">{item?.order_id}</p>
                    </td>
                    <td className="Proprice">
                      <p className="font-13"> {item?.delivery_status}</p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
}

export default ViewOrderModal;
