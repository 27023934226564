import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

function AddressCard() {
    const [location, setLocation] = useState("location_1");
    const LocationVal = (e) => {
        setLocation(e)
    }

    let address = [
        {
            location: "location_1",
            country: "United States",
            state: "Texas",
            address: "10515 Fox Ave Fairdale, Kentucky(KY), 40118",
            phone: "+44-123-456-789"
        },
        {
            location: "location_2",
            country: "United States",
            state: "Texas",
            address: "10515 Fox Ave Fairdale, Kentucky(KY), 40118",
            phone: "+44-123-456-789"
        }
    ];

  return (
    <div className='col-md-12 col-sm-12 col-12 MyAddress'>
    <div className='container'>
            {address.map((item, index) => {
                return (
                    <div key={index} className={(location === `${item.location}`) ? `crd-btn-active my-3 ` : `crd-btn-nonactive my-3`}>
                        
                        <div className='flx-radio-address '>
                            <div className='radio_portion '>
                                <input type='radio'
                                checked={location === item.location}
                                onChange={() => { LocationVal(`${item.location}`) }}
                                    value={`${item.location}`}
                                    name='saved_address' />
                            </div>
                            <div className='text-portion'>
                                <div className='top_portion'>
                                    <div className='d-flex'>
                                        <p>Country: <span>United States</span></p>
                                        <p className='ml-5'>State: <span>Texas</span></p>
                                    </div>
                                    {(location === `${item.location}`) ? <div>
                                        <FontAwesomeIcon
                                            icon={faCircleCheck}
                                            className="tick-color"
                                        />
                                    </div> : <></>}
                                </div>
                                <p>Address : 10515 Fox Ave Fairdale, Kentucky(KY), 40118</p>
                                <p className='mb-0'>Phone : +44-123-456-789</p>
                                {(index === 0) ? <span className='default_opt'>Default</span> : <></>}
                            </div>
                        </div>
                    </div>
                )
            })}
    
    </div>
            </div>
  )
}

export default AddressCard