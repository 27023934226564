import React, { useState } from "react";
import "../../assets/css/Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faMagnifyingGlass,
  faHeart,
  faCartShopping,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faLinkedinIn,
  faInstagram,
  faFacebookSquare,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import { Link, Outlet, useNavigate } from "react-router-dom";
import { headlogo } from "../../constant";




function Header(props) {
  const {bg} = props
  const Navigate = useNavigate();
  const [collapse, setCollapse] = useState(false);
  const [loading, setLoading] = useState(false);
  

  const toggleCollapse = () => {
    console.log("collapse", collapse);
    setCollapse(!collapse);
  };

  const isLogin = false

 

  return (
    <>
     <div>
        <section className={`header nav-bottom ${bg ? "nav-color header2" : "null" }`}>
          <nav className="navbar navbar-dark navbar-expand-md navigation-clean-search">
            <div className="container">
              <Link className="navbar-brand navbar-left" to="/">
                <img
                  src={headlogo}
                  target="_self"
                  className="logo-width head-logo"
                  alt=""
                />
              </Link>

              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleCollapse}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className={"collapse navbar-collapse " + (collapse && "show")}
                id="navbarNavDropdown"
              >
                {/* <ul className={`nav navbar-nav navbar-left${bg ? "header-und" : "header-n"}`}> */}
                <ul className="nav navbar-nav navbar-left">
                  <li className="nav-item" role="presentation">
                    <Link to="/" className="nav-link">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link to="/features" className="nav-link">
                    Features
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link to="/shop" className="nav-link">
                      Shop
                    </Link>
                  </li>

                
                 
                  <li className="nav-item" role="presentation">
                    <Link to="/" className="nav-link">
                    Gallery
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link to="/contact-us" className="nav-link">
                    Contact
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link to="/profile" className="nav-link">
                    <FontAwesomeIcon
                            icon={faUser}
                            className="social-color icon-style"
                          />
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link to="/cart" className="nav-link">
                      <FontAwesomeIcon
                          icon={faCartShopping}
                          className="social-color icon-style"
                        />
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link to="/" className="nav-link">
                      <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          className="social-color icon-style"
                        />
                    </Link>
                  </li>
               
                </ul>
              
              </div>
            </div>
          </nav>
        </section>
      </div>
      <Outlet />
    </>
  );
}

export default Header;

