import React from 'react'
import Header from '../../components/Header/Header'
import '../../assets/css/Home.css'
import { banner1, fe1, fe2, fe3, homeN1, homeN2, htWork1, news1, news2 } from '../../constant'
import OurProductsSlider from '../../components/OurProducts/OurProductsSlider'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import HeaderBanner from '../../components/Slider/HeaderBanner'

function Home(props) {
    const newsData = [
        {
            id:1,
            img: news1
        },
        {
            id:2,
            img: news2,
        },
    ]
  return (
    <>
     <Header  bg={false}/>
    <div className='banner'>
        <HeaderBanner/>
       {/* <img src={banner1} alt="" className='image-fluid image-width' />
       <div className='banner-content'>
            <div className='banner-inner'>
                <h2 className='banner-rotateText'>Swift</h2>
                <h2 className='banner-title'>E Bikes</h2>
            </div>
            <hr className='hr-line'/>
            <div>
                <h4 className='banner-subTitle'> Premium Bikes</h4>
                <h4 className='banner-subTitle'>at Affordable Prices</h4>
            </div>
       </div> */}
    </div>
    {/* <HeaderBanner /> */}
    <section className='Home'>
        <div className='Features'>
            <div className='container'>
                <h2 className='main-title'>Features</h2>
                <p className='main-para'>Find your dream bike here</p>
                <div className='row'>
                    <div className='col-md-4'>
                        <h3 className='title'>Lorem Ipsum Dolor, Sit Amet</h3>
                        <p className='para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                            when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        <p className='para'>
                            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin
                         literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney 
                         College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and 
                         going through the cites of the word in classical literature, discovered the undoubtable source.
                        </p>
                        <div>
                            <button className='btn learn'>Learn More</button>
                        </div>
                    </div>
                    <div className='col-md-2 feat-dv1'>
                        <img src={fe2} alt="" 
                            className='image-fluid image-width' 
                            style={{height: '100%'}}
                        />
                    </div>
                    <div className='col-md-4 feat-dv1'>
                        <img src={fe1} alt="" 
                            className='image-fluid image-width' 
                            style={{height: '100%'}}
                        />
                    </div>
                    <div className='col-md-2 feat-dv1'>
                        <img src={fe3} alt="" 
                            className='image-fluid image-width' 
                            style={{height: '100%'}}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className='our-products'>
            <OurProductsSlider />
        
        </div>

        <div className='NewArrivals'>
            <div className='container'>
                <p className='main-para'>Find your dream bike here</p>
                <h2 className='main-title'>New Arrivals</h2>
                <div className='row inner-container'>
                    <div className='col-md-6'>
                        <img src={homeN1} alt="" className='image-fluid image-width' />
                    </div>
                    <div className='col-md-6'>
                        <h2 className='block-title'>E-Bike E40 (2022)</h2>
                        <p className='block-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                            when an unknown printer took a galley of type and scrambled it 
                            to make a type specimen book. It has survived not only five centuries, but also 
                            the leap into electronic typesetting, remaining essentially unchanged. 
                            It was popularised in the 1960s with the release of Letraset sheets containing 
                            Lorem Ipsum passages, and more recently with desktop publishing software like Aldus 
                            PageMaker including versions of Lorem Ipsum</p>
                        <p className='block-para'> 
                        it to make a type specimen book. It has survived not only five centuries, but also 
                        the leap into electronic typesetting, remaining essentially unchanged. It was popularised in 
                        the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.
                        </p>
                        <div className='btn-container'>
                            <button className='btn viewAll'>View Details</button>
                        </div>
                    </div>
                </div>
                <div className='row inner-container'>
                        <div className='col-md-6'>
                            <h2 className='block-title'>E-Bike E40 (2022)</h2>
                            <p className='block-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                when an unknown printer took a galley of type and scrambled it 
                                to make a type specimen book. It has survived not only five centuries, but also 
                                the leap into electronic typesetting, remaining essentially unchanged. 
                                It was popularised in the 1960s with the release of Letraset sheets containing 
                                Lorem Ipsum passages, and more recently with desktop publishing software like Aldus 
                                PageMaker including versions of Lorem Ipsum</p>
                            <p className='block-para'>
                            it to make a type specimen book. It has survived not only five centuries, but also 
                            the leap into electronic typesetting, remaining essentially unchanged. It was popularised in 
                            the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.
                            </p>
                            <div className='btn-container'>
                            <button className='btn viewAll'>View Details</button>
                        </div>
                        </div>
                        <div className='col-md-6'>
                            <img src={homeN2} alt="" className='image-fluid image-width' />
                        </div>
                    </div>
            </div>
        </div>

        <div className='work-container'>
            <div className='container'>
                <p className='main-para'>Find your dream bike here</p>
                <h2 className='main-title'>How it works</h2>
                <div className='container'>
                    <div className='video-container'>
                        <img src={htWork1} alt="" className='image-fluid image-width'/>
                    </div>
                    
                </div>
            </div>
        </div>

        <div className='News'>
            <div className='container'>
                <p className='main-para'>Find your dream bike here</p>
                <h2 className='main-title'>Latest News</h2>

                {newsData?.map((item,index)=>{
                    return(
                        <div className='news-letter' key={index}>
                        <div className='news-img'>
                            <img src={item?.img} alt="" className='image-fluid image-width' />
                        </div>
                        <div className='news-card'>
                            <div className='cardHeader'>
                                <h4 className='cardTitle'>Sed ut perspiciatis unde omnis iste</h4>
                            </div>
                            <p className='cardPara'>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis 
                                praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias
                                 excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui 
                                 officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem
                                  rerum facilis est et expedita distinctio.
                            </p>
                            <Link to="/">View Details</Link>
                            
                        </div>
                    </div>
                    )
                })}
              
            </div>
        </div>
        <Footer />
    </section>
       
    </>
  )
}

export default Home