import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {  headlogo } from "../../constant";
import '../../assets/css/Footer.css'
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {



  return (
    <>
      <footer className="footer cover-banner">
        <div className="container">
          <div className="inner-container">
            <div className="row pb-3">
              <div className="col-md-4 widgets2 border-right">
                <div className="footer-logo">
                  <img src={headlogo} className="image-fluid" alt="" />
                </div>
                <h4 className="pb-3 first-col">
                    At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
                </h4>
              </div>
              <div className="col-md-4 widgets2 border-right">
                <h4 className="pb-3 text-white mainText">Quick links</h4>
                <div className="quick-link">
                  <ul>
                    <li>
                      <Link to="/about-us"> About Us </Link>
                    </li>
                    <li>
                      <Link to="/features"> Features </Link>
                    </li>
                    <li>
                      <Link to="/shop"> Shop </Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                   
                  </ul>
                  <ul>
                    <li>
                      <Link to="/"> Latest News </Link>
                    </li>
                    <li>
                      <Link to="/"> FAQ </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy"> Privacy & Policy </Link>
                    </li>
                    <li>
                      <Link to="/cookie-policy">Terms & Conditions</Link>
                    </li>
                   
                  </ul>
                </div>
              </div>
              <div className="col-md-4 widgets2">
                <h4 className="pb-3 text-white mainText" style={{textAlign: 'center'}}>Contact</h4>
                <p className="mainEmail">info@swiftebikes.com</p>
                <div className="social-icons">
                    <FontAwesomeIcon
                        icon={faFacebookF}
                        className="social-color"
                   
                    />
                     <FontAwesomeIcon
                        icon={faTwitter}
                        className="social-color"
                    />
                     <FontAwesomeIcon
                        icon={faInstagram}
                        className="social-color"
                    />
                </div>
              
              </div>
              <div className="col-md-12">
                  <hr className="hr-line"/>
                  <p className="copy-right-text">Swift e biksers | All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </footer>

     
    </>
  );
};

export default Footer;
