import React, { useState } from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import SideBar from '../../components/SideBar/SideBar'
import '../../assets/css/Profile.css'
import { Userpic } from '../../constant'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

function ChangePassword() {
    const [editProfile, setEditProfile ] = useState(false)
    const [newFile, setNewFile] = useState("");
    const [fileupload, setFileupload] = useState("");
    const [uploadLoading, setUploadLoading] = useState(false);

  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [ contact, setContact ] = useState();


    const handleImageUpload = (e) => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
          setNewFile(file);
          setFileupload(reader.result);
        };
        reader.readAsDataURL(file);
        setUploadLoading(true);
      };

      const EditProfile = (e) => {
        setEditProfile(!editProfile)
        e.preventDefault();
       
      };
    
      
  return (
   <>
        <Header bg={true}/>
            <div className='url-head'>
                <p className='title'>Security</p>
            </div>

        <div className="dashboard">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                    <h2 className="page-name">Security</h2>
                    <SideBar />
                    </div>
                    <div className="col-md-9">
                        <div className="Profile change-password">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                            <div className="header">
                                <div className="flex-style">
                                    <div className="profile-img">
                                        <img
                                            src={Userpic}
                                            alt=""
                                            className="image-fluid image-width"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <p className="name">John Doe</p>
                                    <p className="sub-title">Lorem Ipsum</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-6 col-sm-6 col-6">
                            <div className="save-container">
                            {editProfile ? (
                                <button type="button" className="btn saveBtn btn-primary"
                                    onClick={(e) => EditProfile(e)}
                                    // disabled={loading}
                                >
                               Save
                            </button>
                            ) : (
                                <button type="button" className="btn saveBtn btn-primary"
                                onClick={()=>setEditProfile(!editProfile)}
                                >
                            Edit Profile
                            </button>
                            )}
                            
                            </div>
                        </div> */}

                    <div className="row user-fields-change">
                   
                        <div className="col-md-6 col-sm-12 col-12 mt-4">
                        <p className="input-label">Password</p>
                        <input 
                            type="text" 
                            placeholder="******" 
                            value={firstName}
                            onChange={(e)=>setFirstName(e.target.value)}
                        />
                        </div>
                        <div className="col-md-6 col-sm-12 col-12 mt-4">
                        <p className="input-label">Change Password</p>
                        <input 
                            type="text" 
                            placeholder="******" 
                            value={lastName}
                            onChange={(e)=>setLastName(e.target.value)}
                        />
                        </div>
                        <div className="col-md-6 col-sm-12 col-12 mt-4">
                        <p className="input-label">Email Address</p>
                        <input 
                            type="text" 
                            placeholder="******" 
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                            disabled={true}
                        />
                        </div>
                        <div className="col-md-6 col-sm-12 col-12 mt-4">
                                <button className='btn save-btn'>Save</button>
                        </div>
                       
                    
                      
                    </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
   </>
  )
}

export default ChangePassword