import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "../pages/About/About";
import Cart from "../pages/Cart/Cart";
import Checkout from "../pages/Checkout/Checkout";
import Contact from "../pages/Contact/Contact";
import Features from "../pages/Features/Features";
import Home from "../pages/Home/Home";
import PrivacyPolicy from "../pages/PolicyPages/PrivacyPolicy";
import ChangePassword from "../pages/Profile/ChangePassword";
import Profile from "../pages/Profile/Profile";
import ProductDetails from "../pages/Shop/ProductDetails";
import Reviews from "../pages/Reviews/Reviews";
import Shop from "../pages/Shop/Shop";
import MyOrders from "../pages/Profile/MyOrders";
import Wishlist from "../pages/Profile/Wishlist";
import MyAddress from "../pages/Profile/MyAddress";
import TermCondition from "../pages/PolicyPages/TermCondition";
import RefundPolicy from "../pages/PolicyPages/RefundPolicy";


function PublicRoutes() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about-us" element={<About />} />
          <Route path="shop" element={<Shop />} />
          <Route path="product-details" element={<ProductDetails />} />

          <Route path="features" element={<Features />} />
          <Route path="contact-us" element={<Contact />} />
          <Route path="cart" element={<Cart />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="reviews" element={<Reviews />} />
          
          <Route path="profile" element={<Profile />} />
          <Route path="my-orders" element={<MyOrders />} />
          <Route path="my-wishlist" element={<Wishlist />} />
          <Route path="my-address" element={<MyAddress />} />

          <Route path="security" element={<ChangePassword />} />

          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="refund-policy" element={<RefundPolicy />} />
          
          <Route path="term-condition" element={<TermCondition />} />
          
          
          
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default PublicRoutes;
