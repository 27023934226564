

import "./App.css";
import PublicRoutes from "./routes/PublicRoutes";
import './assets/css/Style.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <>
    <PublicRoutes />
    </>
 
  );
}

export default App;
